import { ApiConnection, ApiProperty, SourceType } from '@mri-platform/import-export/common-state';
import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';
import { TransformMetadataDetails, TransformationParameter } from './mapper-transformation.model';

export interface Mapper {
  id: number;
  name: string;
  clientId?: number;
  clientName?: string;
  displayName?: string;
  platformName?: string;
  context?: string;
  sourceType: SourceType;
  destinationType?: SourceType;
  destinationFileId?: number;
  sourceConnectionId?: number;
  destinationConnectionId?: number;
  columnMaps?: FilterColumnMap[];
  headerColumnMaps?: FilterColumnMap[];
  connectionAlias?: string;
  application?: string;
  sourceFileId?: number;
  sourceFile?: File;
  sourceConnection?: ApiConnection;
  destinationFile?: File;
  destinationConnection?: ApiConnection;
  sourceColumnMaps?: FilterColumnMap[];
  sourceHeaderColumnMaps?: FilterColumnMap[];
  standard?: boolean;
  canEdit?: boolean;
  transformMetadataDetails?: TransformMetadataDetails;
}

export type HeaderColumnMap = Pick<Mapper, 'headerColumnMaps'>;

export enum MapperCustomAction {
  SetStandardMapper = 'SetStandardMapper',
  DownloadMapper = 'DownloadMapper',
  UploadMapper = 'UploadMapper',
  ViewPage = 'ViewPage',
  OverrideAuditInfo = 'OverrideAuditInfo'
}

export enum ParameterType {
  Int32 = 'Int32',
  Double = 'Double',
  String = 'String',
  Boolean = 'Boolean',
  Array = 'Array',
  Object = 'Object',
  DateTime = 'DateTime',
  None = 'None'
}

export type MapperAction = MapperCustomAction | CrudAction;

export const MapperAction = { ...MapperCustomAction, ...CrudAction };

export const Mapper = createModelMetadata<Mapper>({
  entityName: 'Mapper',
  authorization: {
    ...AuthzContextsActionMap.crudFor(EntityName.Mapper),
    [MapperAction.SetStandardMapper]: {
      action: [MapperAction.SetStandardMapper, MapperAction.Update],
      resource: EntityName.Mapper
    },
    [MapperAction.DownloadMapper]: {
      action: [MapperAction.DownloadMapper, MapperAction.Read],
      resource: EntityName.Mapper
    },
    [MapperAction.UploadMapper]: {
      action: [MapperAction.UploadMapper, MapperAction.Create],
      resource: EntityName.Mapper
    },
    [MapperAction.ViewPage]: {
      action: [MapperAction.ViewPage],
      resource: EntityName.Mapper
    },
    [MapperAction.OverrideAuditInfo]: {
      action: [MapperAction.OverrideAuditInfo],
      resource: EntityName.Mapper
    }
  }
});

export type FilterColumnMap = Omit<ColumnMap, 'id'>;

export interface File {
  id: number;
  name: string;
  metaData: FileMetaData;
}

interface FileMetaData {
  contentType: string;
  fileColumns: FileColumn[];
  headerIndices?: number[];
  maxNonEmptyHeaderColumnIndex: number;
  sheetName?: string;
}

interface FileColumn {
  columnName: string;
  dataType: string;
  id: number;
  columnNumber?: number;
  rowNumber?: number;
}

export interface ColumnMap {
  id: number;
  sourcePath: string;
  sourceType: string;
  destinationType: string;
  destinationPath: string;
  status?: boolean;
  transformationMetadata: TransformationMetadata;
  rowNumber?: number;
  columnNumber?: number;
}

export interface TransformationMetadata {
  transformations: Transformation[];
}

export type Transformation = {
  type: string;
} & TransformationParameter;

export function createMapper(): Mapper {
  return {
    id: -1,
    name: '',
    sourceType: SourceType.FILE,
    destinationType: SourceType.CONNECTION,
    destinationFileId: undefined,
    sourceConnectionId: undefined,
    destinationConnectionId: undefined,
    sourceFileId: undefined,
    columnMaps: []
  };
}

const getColumnMapFromFileColumn = ({ columnName, id, dataType, rowNumber, columnNumber }: FileColumn): ColumnMap => ({
  sourcePath: columnName ? columnName : '',
  sourceType: dataType,
  destinationPath: '',
  destinationType: '',
  status: false,
  id: id,
  transformationMetadata: {
    transformations: []
  },
  rowNumber,
  columnNumber
});

export const getFileColumnMaps = (list?: FileColumn[]): ColumnMap[] => list?.map(getColumnMapFromFileColumn) || [];

const getColumnMapFromParamater = ({ path, type }: Omit<ApiProperty, 'id'>): FilterColumnMap => ({
  sourcePath: path ?? '',
  sourceType: type,
  destinationPath: '',
  destinationType: '',
  status: false,
  transformationMetadata: {
    transformations: []
  }
});

export const getConnectionColumnMaps = (list: Omit<ApiProperty, 'id'>[] | undefined): FilterColumnMap[] =>
  list?.map(getColumnMapFromParamater) || [];

export const getDestinationType = (sourceType: SourceType): SourceType =>
  sourceType === SourceType.CONNECTION ? SourceType.FILE : SourceType.CONNECTION;

export const setMapperSourceColumnMap = (mapper: Mapper) => {
  const sourceColumnMaps = mapper.sourceFileId
    ? getFileColumnMaps(mapper.sourceFile?.metaData.fileColumns)
    : mapper.sourceConnection
      ? getConnectionColumnMaps(mapper.sourceConnection.metaData?.responseProperties)
      : mapper.columnMaps;

  return sourceColumnMaps?.length
    ? {
        ...mapper,
        sourceColumnMaps
      }
    : mapper;
};

export const getDestination = (mapper: Mapper) =>
  mapper.sourceType === SourceType.CONNECTION
    ? getDestinationFileList(mapper.destinationFile)
    : getDestinationConnectionList(mapper.destinationConnection);

export const getDestinationFileList = (destination: File | undefined) =>
  (!!destination &&
    destination.metaData?.fileColumns?.length &&
    destination.metaData.fileColumns.map(column => ({ type: column.dataType, name: column.columnName }))) ||
  [];

export const getDestinationConnectionList = (destination: ApiConnection | undefined) =>
  (!!destination &&
    destination.metaData?.requestProperties &&
    destination.metaData.requestProperties.map(column => ({ type: column.type, name: column.path }))) ||
  [];

export interface DestinationColumn {
  path: string;
  isRequired: boolean;
}
